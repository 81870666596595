.title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
}

.divider {
  height: 16px;
}
