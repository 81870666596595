.langSelector {
  width: 120px;

  &Item {
    padding-left: 12px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #f2f2f2;
    }
  }
}
