.imageUploader {
  width: auto !important;
}

.imageItem {
  display: 'inline-block';
  margin-right: 6px;
  margin-bottom: 6px;
  width: 102px;
  height: 102px;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  cursor: pointer;
}
