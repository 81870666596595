.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: rgb(240, 242, 245);
    flex-direction: column;
    box-sizing: border-box;

    .print {
        width: 21cm;
        height: 29.7cm;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
        background-color: #fff;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        .printWarp {
            width: 531.84pt;
            height: 625.44pt;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
        }


        .tip {

            position: absolute;
            top: 24px;
            left: 24px;

            .subtitle {
                font-size: 14px;
                font-weight: 800;
                color: #000;
            }

            .text {
                font-size: 12px;
                color: rgba(153, 153, 153, 1);
            }
        }

    }

    .buttons {
        margin-top: 36px;
    }


}