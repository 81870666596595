/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.im-chat-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.im-chat-scrollbar::-webkit-scrollbar-thumb {
  background-color: #DADDEB;
  border-radius: 20px;
}

.im-chat-scrollbar::-webkit-scrollbar-track {
  opacity: 0;
}

.imageViewer > .container {
  position: unset !important;
}
