.ProductLabelEdit {
    margin: -16px;

    .left {
        background-color: #F0F0F0;
        width: 100%;
        height: 100%;

        .content {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 12px 24px 24px 24px;

            .title {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 12px;
            }

        }

        // 清理组件样式
        :global {
            .PriceTag {
                border: 0px;
            }
        }
    }

    .right {
        padding: 24px 24px;

        .title {
            display: inline-flex;
            align-items: baseline;
            padding: 0px 18px 18px 0px;

            .subtitle {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                color: #303133;
                padding-right: 12px;
            }

            .tip {
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #606266;

            }
        }

        .form {

            .form_label {
                width: 68px;
                text-align: right;

                .form_required {
                    display: inline-block;
                }

                .form_required::before {
                    display: inline-block;
                    margin-inline-end: 4px;
                    color: #ff4d4f;
                    font-size: 14px;
                    font-family: SimSun, sans-serif;
                    line-height: 1;
                    content: "*";
                }
            }


            .form_checkbox {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                left: 8px;

                :global {
                    .ant-checkbox-wrapper+.ant-checkbox-wrapper {
                        margin-inline-start: 0px;
                    }
                }

            }

            .productName_tip {
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: rgb(96, 98, 102, 0.8);
                position: relative;
                left: 80px;
                bottom: 16px;
            }
        }

    }
}


.PirceCurrency {
    position: relative;

    .tip {
        display: inline-flex;
        position: absolute;
        height: 100%;
        width: 120px;
        align-items: center;
        right: -128px;
        color: #999;
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize
    }
}