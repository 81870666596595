@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
}