.dempyou {
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 12px;
  }
  th {
    text-align: center;
  }

  .borderBottomDouble {
    border-bottom: double;
  }
}
