.MinMaxValuation {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.UploadImageTips {
    .tips {
        font-size: 12px;
        color: red;
    }
}

.SelectStoreAddress {
    .address {
        color: rgba(0, 0, 0, 0.45);
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 4px;
        border: 1px dashed rgb(217, 217, 217);
        margin-top: 8px;
        padding: 4px 8px;
    }
}


.SelectAppointment {

    padding: 8px;

    .store {
        margin-bottom: 12px;
    }

    .calendar {
        .dayWarp {
            display: flex;
            justify-content: space-between;
            width: 50%;
            margin-bottom: 12px;

            .dayItems {

                .tag {
                    padding: 8px 12px;
                    border: 1px solid #d9d9d9;
                }
            }
        }

        .timeWarp {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 12px;

            .timeItems {

                .tag {
                    padding: 4px 6px;
                    border: 1px solid #d9d9d9;
                    margin-bottom: 8px;
                }
            }
        }

        .disabled {
            background: rgba(153, 153, 153, 0.2);
            color: rgba(153, 153, 153, 0.6);
            cursor: not-allowed;
        }

    }
}