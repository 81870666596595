body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #f1f2f3;
    box-sizing: border-box;
}

#A4 {
    width: 594.3pt;
    height: 840.51pt;
    background-color: #fff;
    // margin: 0mm auto;
    padding: 110.37pt 33.96pt 110.37pt 33.96pt;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 24px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

.A4_Warp {
    width: 494.3pt;
    height: 740.51pt;
    border: 0px solid red;
    box-sizing: border-box;
    position: relative;
    font-family: Source Han Serif TW;
}

.A4_spaceBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.head {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;

    .logo {
        text-align: center;
        margin-bottom: 12px;

        .img {
            zoom: 2;
        }
    }

    .title {
        font-size: 24px;
        font-weight: 900;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
    }
}

.userInfo {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    zoom: 0.5;
    margin-top: 50px;

    .label {
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .colon {
            // margin-left: 6px;
        }
    }


    .text {
        margin-left: 12px;
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .userArea {
        border: 0px solid blue;
        padding: 8px 0px;

        .top {
            border-bottom: 1px solid rgba(215, 215, 215, 1);
            display: flex;
            box-sizing: border-box;
            align-items: center;
            padding: 12px 0px;
        }

        .userDetail {
            border-bottom: 1px solid rgba(215, 215, 215, 1);
            height: 200px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .itemsWarp {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .items {
                    display: flex;
                    padding: 8px 0px;
                }
            }
        }
    }

    .userInfo_left {
        width: 62%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .userInfo_right {
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.product {
    width: 100%;
    zoom: 0.5;
    margin-top: 50px;

    .table table {
        width: 100%;
        border-collapse: collapse;
        border-bottom: 1px solid rgba(181, 142, 75, 1);

        thead th {
            background-color: rgba(181, 142, 75, 1);
            padding: 20px 30px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            font-size: 20px;

            &:last-child {
                text-align: right;
            }
        }

        tbody tr td {
            padding: 20px 30px;
            font-size: 20px;
            line-height: 24px;
            // border: 1px solid red;

            &:last-child {
                text-align: right;
            }
        }

        .status {
            color: rgba(181, 142, 75, 1);
            font-weight: 900;
        }
    }

    .bottom {
        background-color: rgba(248, 248, 248, 1);
        padding: 12px 0px;

        table {
            width: 100%;
            border-collapse: collapse;

            tbody tr th {
                // border: 1px solid red;
                padding: 20px 30px;
                text-align: left;
                font-size: 20px;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

.contract {
    width: 100%;
    zoom: 0.5;
    margin-top: 50px;
    height: 666px;
    overflow: hidden;

    .subTitle {
        font-size: 20px;
        line-height: 22px;
        font-weight: 700;
        letter-spacing: 0em;
        text-align: left;
        padding: 15px 0px;
        border-bottom: 1px solid rgba(215, 215, 215, 1);

    }

    .table {
        width: 100%;
        border-collapse: collapse;

        .thead {
            .tr {
                border-bottom: 1px solid rgba(215, 215, 215, 1);

                .th {
                    width: 100%;
                    display: inline-block;
                    padding: 24px 12px;
                    text-align: left;
                    box-sizing: border-box;
                    font-size: 20px;
                    line-height: 22px;

                    &:nth-child(1) {
                        width: 15%;
                    }

                    &:nth-child(2) {
                        width: 60%;
                    }

                    &:nth-child(3) {
                        width: 25%;
                        text-align: right;
                    }
                }

            }
        }

        .tbody {
            .tr {
                border-bottom: 1px solid rgba(215, 215, 215, 1);

                .trWarp {
                    display: flex;
                    align-items: center;

                    .td {
                        padding: 24px 12px;
                        display: inline-block;
                        font-size: 20px;
                        line-height: 22px;

                        &:nth-child(1) {
                            width: 15%;
                        }

                        &:nth-child(2) {
                            width: 60%;
                        }

                        &:nth-child(3) {
                            width: 25%;
                            text-align: right;

                        }
                    }
                }

                .signatureWarp {
                    display: flex;
                    justify-content: flex-end;
                    margin: 15px 0px 32px 0px;
                }


            }
        }
    }
}

.tips {
    width: 100%;
    display: flex;
    justify-content: space-between;
    zoom: 0.5;
    margin-top: 30px;

    .left {
        width: 62%;
        color: rgba(102, 102, 102, 1);

        ul {
            li {
                margin: 0px 0px 0px -10px;
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
            }

            li:marker {
                color: rgba(102, 102, 102, 1);
            }
        }
    }

    .right {
        width: 436px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        // border: 1px solid red;

        .checkbox {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 16px;
            font-weight: 400;

            svg {
                zoom: 4;
                margin-right: 2px;
                position: relative;
                top: 0.5px;
            }
        }

        .signatureWarp {
            width: 100%;
        }
    }
}


.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    zoom: 0.5;
    color: rgba(102, 102, 102, 1);
    position: absolute;
    bottom: 0;

    .left {
        width: 62%;
        display: flex;
        align-items: center;
    }

    .right {
        width: 35%;
        display: flex;
        justify-content: space-between;

        .tel {
            display: flex;
            align-items: center;
        }

        .icon {
            display: flex;
            // zoom: 2;
            height: 100%;
            align-items: center;
            position: relative;
            top: 0.5px;
        }

        .email {
            display: flex;
            align-items: center;
        }
    }
}

// 签名样式
.signature {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    width: 436px;

    .text {
        width: 96px;
    }

    .line {
        border-bottom: 1px solid #000;
        width: calc(100% - 96px);
    }
}

// 第一张纸
.content {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.marker {
    // background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(215, 215, 215, 1);
    color: rgb(102, 102, 102);
    padding: 12px 14px;
    display: inline-block;
    position: absolute;
    right: 0px;
    zoom: 0.5;
    top: 0px;
}