.barTop {

    .left {
        .dateWeek {
            display: inline-flex;
            align-items: center;

            .rightBtn {
                cursor: pointer;
            }

            .leftBtn {
                cursor: pointer;
            }
        }

        .btn {
            display: inline-block;
        }
    }

    .right {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        // :global {
        //     .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        //         color: #fff;
        //         background: #1677ff;
        //         border-color: #1677ff;
        //     }
        // }
    }
}