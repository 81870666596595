.ImageSliceShow {
  display: flex;

  .more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    font-size: 10px;
    color: #a6a6a6;
    cursor: pointer;
  }
}